<template>
  <div class="component-container">
    <h4 v-show="title">{{ title }}</h4>
    <div class="select">
      <Field
        v-model="mValue"
        :name="name"
        :rules="rules"
        as="select"
        @input="$emit('updated', $event)"
      >
        <option v-if="placeholder" value="" disabled selected hidden>
          {{ placeholder }}
        </option>
        <option
          v-for="(value, index) in values"
          :key="index"
          :value="value.id"
          :name="name"
        >
          {{ value.description }}
        </option>
      </Field>
      <ErrorMessage :name="name" />
    </div>
  </div>
</template>

<script>
import { toRefs, onBeforeMount, ref } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import { notify } from '@kyvg/vue3-notification';
import useModelWrapper from '../composables/modelWrapper';
import { $axios, getWithProhipotecasToken } from '../services/index';
import { launchDefaultErrorNotification } from '../composables/utils';

export default {
  name: 'CustomSelect',
  emits: ['updated', 'update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { options } = toRefs(props);

    const values = ref([]);

    onBeforeMount(async () => {
      if (Array.isArray(options.value)) {
        values.value = options.value;
      } else if (options.value.requiresProhipotecasToken) {
        const data = await getWithProhipotecasToken(options.value.endpoint);
        if (!data.success) {
          launchDefaultErrorNotification(notify);
        }

        values.value = data.data;
      } else {
        $axios.get(options.value.endpoint).then(
          (res) => {
            if (!res.data.success) {
              launchDefaultErrorNotification(notify);
            }
            values.value = res.data.data;
          },
          () => {
            launchDefaultErrorNotification(notify);
          },
        );
      }
    });

    return {
      values,
      mValue: useModelWrapper(props, emit),
    };
  },
};
</script>

<style scoped lang="scss">
select {
  width: 100%;
  background-color: $input-color !important;
  border: 1px solid #333;
  border-radius: 4px;
  display: block;
  height: 50px;
  margin: 0 auto;
  padding: 0 15px;
  transition: all 0.3s;
  background-color: white;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;

  &:hover,
  &:focus {
    background-color: white !important;
    box-shadow: none;
    outline: 0 none;
  }
}
</style>
